import { useSanaTexts } from 'components/sanaText';
import { makeRichText } from 'utils/render';
import { StyleMode } from './constants';
import SanaTextWrapper from './SanaTextWrapper';

type Props = {
  model: {
    key: string;
    styleMode: StyleMode;
  };
};

const SanaTextBlock = ({ model: { key, styleMode } }: Props) => {
  const { texts: [text], loaded } = useSanaTexts([{ key }]);
  
  if (!loaded || text === null)
    return null;

  return (
    <SanaTextWrapper styleMode={styleMode}>
      {makeRichText(text)}
    </SanaTextWrapper>
  );
};

export default SanaTextBlock;
