import styles from './SanaTextBlock.module.scss';
import { memo, ReactNode } from 'react';
import { StyleMode } from './constants';

type Props = {
  styleMode: StyleMode;
  children: ReactNode;
};

const SanaTextWrapper = ({ styleMode, children }: Props) => {
  switch (styleMode) {
    case StyleMode.Heading1:
      return <h1 className={styles.resetMargin}>{children}</h1>;
    case StyleMode.Heading2:
      return <h2 className={styles.resetMargin}>{children}</h2>;
    case StyleMode.Heading3:
      return <h3 className={styles.resetMargin}>{children}</h3>;
    case StyleMode.Heading4:
      return <h4 className={styles.resetMargin}>{children}</h4>;
    case StyleMode.Heading5:
      return <h5 className={styles.resetMargin}>{children}</h5>;
    case StyleMode.None:
      return <>{children}</>;
    default:
      return null;
  }
};

export default memo(SanaTextWrapper);
