exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SanaTextBlock_reset-margin{margin:0}", ""]);

// exports
exports.locals = {
	"reset-margin": "SanaTextBlock_reset-margin",
	"resetMargin": "SanaTextBlock_reset-margin"
};